<template>
  <b-modal
    id="search-appointment-modal"
    hide-footer
    hide-header
    centered
    :size="'xl'"
    @hidden="onHidden"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Buscar agendamento</p>
      <Close
        class="close"
        style="cursor: pointer"
        @click="closeModal"
      />
    </div>
    <div class="body d-flex flex-column">
      <b-row class="d-flex mb-3">
        <b-col
          cols="5" 
          id="multiselect-container"
        >
          <Search class="input-icon" />                      
          <multiselect
            class="with-border multiple multiselect"
            v-model="selectedPatients"
            placeholder=" Pesquisar paciente(s)"
            :options="patientOptions"
            :allow-empty="true"
            :option-height="40"
            :showLabels="false"
            track-by="id"
            label="name"
            :multiple="true"
            :loading="loading"
            :internal-search="false"
            :close-on-select="false"
            @search-change="debounceSearchPatients"
          > 
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
                <br/>
                <span class="option-birthdate">Nasc: ({{ props.option.birthday }}) / </span>
                <span class="option-cpf">CPF: {{ props.option.cpf }}</span>
              </div>
            </template>

            <template slot="selection" slot-scope="{ values }">
              <span v-if="values.length" class="mx-4 inline-content">
                {{ values.map(el => el.name).join(', ')  }}
              </span>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult">Nenhum resultado encontrado </template>
          </multiselect>
        </b-col>

        <b-col cols="3">
          <multiselect
            :options="schedulingStatus"
            :showLabels="false"
            :searchable="true"
            :allow-empty="true"
            v-model="schedulingStatusValue"
            track-by="value"
            label="label"
            placeholder="Situação"
            @select="onSelectStatus"
            class="with-border multiple"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>

            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.label }}
            </template>

            <template slot="noOptions"> Nenhuma opção </template>

            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>

        <b-col cols="2">
          <b-form-group>
            <date-picker
              class="full"
              placeholder="Data"
              v-model="date"
              format="DD/MM/YYYY"
              :clearable="false"
              :lang="langDatePicker"
            />
            <span class="pos-icon">
              <Close 
                v-if="date"
                @click="date = null"
              />
            </span>
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-button
            variant="primary"
            block
            @click="searchAppointments"
          >
            Buscar
          </b-button> 
        </b-col>
      </b-row>

      <div class="px-2">
        <b-row 
          v-if="appointments.length"
          class="border"
        >
          <table class="table mb-0">
            <tr class="td-head">
              <th></th>
              <th width="20%">Situação</th>
              <th width="15%">Data/Horário</th>
              <th width="20%">Paciente</th>
              <th width="25%">Itens</th>
              <th width="20%">Profissional/Sala/Setor</th>
            </tr>
            
            <tr
              class="result"
              v-for="appointment in appointments"
              :key="appointment.id"
            >
              <td>                 
                  <Check
                    class="check"
                    :disabled="['FINISHED', 'HAPPENING'].includes(appointment.status)"
                    @change="selectAppointment(appointment)"
                    :value="Boolean(selectedAppointments?.includes(appointment.id))"
                  />
              </td>

              <td 
                width="20%"
                @click="editAppointment(appointment)"
              >
                <div class="d-flex align-items-center">
                  <div class="mr-1" :class="`situation ${appointment.status.toLowerCase()}`"/>                
                  {{parseStatus(appointment.status)}}
                </div>
              </td>

              <td 
                width="15%"
                @click="editAppointment(appointment)"  
              >
                {{ moment(appointment.start_datetime).format('DD/MM/YY') }}
                <div class="subtitle">
                  {{ moment(appointment.start_datetime).format('HH:mm') }}-{{ moment(appointment.end_datetime).format('HH:mm') }}
                </div>
              </td>

              <td 
                width="20%"
                @click="editAppointment(appointment)"  
              >
                {{ appointment.patient.name }}
                <div class="subtitle">
                  {{ 
                    (appointment.patient.birthday) ? `Nasc.: ${moment(appointment.patient.birthday).format('DD/MM/YYYY')}` : 'Nasc. não informado' 
                  }} / 
                  {{ 
                    (appointment.patient.cpf) ? `CPF: ${appointment.patient.cpf}` : 'CPF não informado' 
                  }}
                </div>
              </td>

              <td 
                width="25%"
                @click="editAppointment(appointment)"  
              >
                {{appointment?.appointment_items[0]?.item?.name?.length > 25 ? appointment?.appointment_items[0]?.item?.name?.substring(0,20)+'...' : appointment?.appointment_items[0]?.item?.name}}
                <span 
                  v-if="appointment?.appointment_items?.length > 1"
                  class="pill"
                >  
                  +{{appointment?.appointment_items?.length-1}}
                </span>
              </td>

              <td 
                width="20%"
                @click="editAppointment(appointment)"    
              >
                <div>{{ appointment.professional ? appointment.professional.name : '-' }}</div>
                <div class="subtitle">  
                  {{ appointment.room ? appointment.room.name : '' }}
                </div>
              </td>
            </tr>
          </table>
        </b-row>
      </div>

      <NoFiltersResults 
        v-if="searched && !appointments?.length && (date || selectedPatients?.length || schedulingStatusValue) "
        message="Os filtros aplicados não geraram nenhum resultado." 
      />

      <div 
        v-if="appointments?.length"
        class="d-flex justify-content-end mt-3"
      >
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>   

      <b-row 
        v-if="selectedAppointments?.length"
        class="d-flex justify-content-between px-2"
      >
        <b-button
          variant="outline-danger"
          @click="closeModal"
        >
          Cancelar
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="changeSituation"
        >
          Alterar situação
        </b-button>
      </b-row>
    </div>
    <ChangeSituationModal
      :appointments="selectedAppointments"
      @change-situation="handleChangeSituation"
    />
  </b-modal>
</template>
<script>
import moment from 'moment'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { debounce } from 'lodash';
import { statusOptions } from '@/utils/appointmentHelper'

export default {
  components: {
    ChangeSituationModal: () => import('./ChangeSituationModal.vue'),
    Check: () => import('@/components/General/Check'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    NoFiltersResults: () => import('@/components/General/noFilterResults'),
    Search: () => import('@/assets/icons/search.svg'),
  },
  props:{
    loadAppointments: Function
  },
  data() {
    return {
      loading: false,
      clinic: getCurrentClinic(),
      date: null,
      selectedPatients: [],
      patientOptions: [],
      page: 1,
      count: 0,
      limit: 0,
      appointments: [],
      selectedAppointments: [],
      langDatePicker: {
        formatLocale: {
	        weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        },
      },
      debounceSearchPatients: debounce(this.searchPatients, 1000),
      searched: false,
      schedulingStatusValue: [],
      schedulingStatus: statusOptions,
    }
  },
  methods: {
    moment,
    async searchPatients(query) {
      this.patientOptions = []
      if(query?.length < 2) return
      this.loading = true
      await this.api.searchPatients(this.clinic.id, query)
        .then(res => {
          const newPatients = res.data.map(patient => {
            return {
              name: patient.name,
              id: patient.id,
              birthday: (patient.birthday) ? moment(patient.birthday).format('DD/MM/YYYY') : 'Não informado',
              cpf: patient.cpf ?? 'Não informado'
            }
          })
          this.patientOptions = this.patientOptions.concat(newPatients)          

          this.patientOptions = this.patientOptions.filter(function(patient, pos, self) {
            return self.findIndex(searchPatient => searchPatient.id === patient.id) === pos;
          });
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => (this.loading = false))
      
		},
    async searchAppointments() {
      if(!this.selectedPatients) {
        this.appointments = [];
        this.schedulingStatusValue = [];
        this.count = 0;
        this.limit = 0;
        return;
      }
      try {       
        const patientIds = this.selectedPatients.filter(opt => opt.name !== 'Todos').map(opt => {
          return opt.id
        })     
        const res = await this.api.searchAppointments(this.clinic.id, this.page, {
          patientIds: patientIds,
          date: this.date,
          status: this.schedulingStatusValue.value,
        });
        this.appointments = res.data.data;
        this.count = res.data.total;
        this.limit = res.data.per_page;
        this.searched = true
      } catch (err) {
        this.$toast.error(err.message);
      }
    },
    editAppointment(appointment) {
      this.$bvModal.hide('search-appointment-modal')
      this.$emit('openAppointment', appointment.id);
    },
    onHidden() {
      this.selectedPatients = []
      this.selectedAppointments = []
      this.patientOptions = []
      this.appointments = []
      this.schedulingStatusValue = []
      this.date = null
      this.searched = false
    },
    closeModal(){
      this.$bvModal.hide('search-appointment-modal')
    },
    selectAppointment(appointment){
      if(this.selectedAppointments.includes(appointment.id)) this.selectedAppointments.splice(this.selectedAppointments.indexOf(appointment.id), 1)
      else this.selectedAppointments.push(appointment.id)
    },
    parseStatus(status) {
      switch(status) {
        case 'ATTEND':
          return 'Compareceu';
        case 'SCHEDULED':
          return 'Sem situação';
        case 'CONFIRMED':
          return 'Confirmado';
        case 'CONFIRMED_BY_PATIENT':
          return 'Confirmado pelo paciente';
        case 'FINISHED':
          return 'Finalizado';
        case 'MISSED':
          return 'Faltou';
        case 'CANCELLED':
          return 'Cancelado';
        case 'CANCELLED_BY_PATIENT':
          return 'Cancelado pelo paciente';
        case 'WAITING':
          return 'Aguardando atendimento';
        case 'HAPPENING':
          return 'Atendimento médico';
        case 'EXAMINATING':
          return 'Em exames';
        case 'DILATING':
          return 'Dilatando';
        case 'PRE_FINISHED':
          return 'Pré-consulta finalizada';
        case 'IN_PRE_CONSULTATION':
          return 'Em pré-consulta';
        default:
          return status;
      }
    },
    changeSituation(){
      this.$bvModal.show('change-situation-modal')
    },
    handleChangeSituation(){
      this.selectedAppointments = []
      this.searchAppointments()
      this.loadAppointments()
    },
    onSelectStatus(option) {
      if (!option.value) {
        return
      }
      this.schedulingStatusValue = option
   },
  },
  watch: {
    page() { this.searchAppointments() }
  }
}
</script>
<style lang="scss">
  #search-appointment-modal {
    .modal-content {
      border-radius: 8px !important;
    }

    .modal-body {
      padding: 0 !important;

      .header {
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .title {
          font-family: 'Nunito Sans';
          font-weight: 600;
          font-size: 18px;
          margin: 0;
          color: var(--type-active);
        }

        svg {
          width: 24px;
          height: 24px;
          fill: var(--dark-blue);
        }
      }

      .body {
        padding: 24px;

        .input-container {
          position: relative;
        }

        .pill{
          background: var(--neutral-100) ;
          border-radius: 30%;
          padding: 1px 8px;
        }

        .border{
          border: 1px solid var(--neutral-200) !important;
          border-radius: 8px !important;
          padding: 0;
        }
        .table {
          background: #FFFFFF;
          border-radius: 8px !important;
          overflow: hidden;
          padding: 20px 0 ;
          
          th {
            padding: 10px 15px;
          }

          tr {
            padding: 10px 15px;
            font-size: 0.8rem;
            vertical-align: middle;
            color: var(--type-active);
          }

          td{
            vertical-align: middle;
          }

          .td-head {
            font-weight: 600;
            font-size: 14px;
            color: var(--type-active);
            background-color: var(--neutral-100);
          }
          .subtitle {
            white-space: nowrap;
            color: var(--type-placeholder);
          }

          .result:hover {
            cursor: pointer;
            background: #f4f5fb;
          }
        }
      }
    }
        
    .input-icon {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      margin-right: 8px;
      position: absolute;
      transform: translateY(-50%);
      left: 16px;
      top: 40%;
      z-index: 2;
    }

    .inline-content {
      display: flex !important;
      flex-direction: row !important;
      overflow: hidden !important;
      max-width: fit-content !important;
      white-space: nowrap;
      text-overflow: ellipsis !important;
    }

    .check{
      margin-right: -20px;
    }

    .pos-icon{      
      width: 24px;
      height: 24px;
      margin-right: 8px;
      position: absolute;
      right: 5px;
      top: 15%;

      svg {
        stroke: var(--neutral-500);
      }
    }
    .situation {
      width: 18px;
      height: 18px;
      border: 2px solid var(--neutral-500);
      border-radius: 50%;

      &.scheduled {
        background: #FFFFFF;
        border: 3px solid #A4B1DF;
      }
      &.confirmed {
        background: #046340;
        border: 3px solid #F2FFE6;
      }
      &.confirmed_by_patient {
        background: #046340;
        border: 3px solid #F2FFE6;
      }
      &.attend {
        background: #0B30B2;
        border: 3px solid #E6F5FF;
      }
      &.missed {
        background: #F63220;
        border: 3px solid #FFD6D2;
      }
      &.cancelled {
        background: #525C7A;
        border: 3px solid #E9EBF2;
      }
      &.cancelled_by_patient {
        background: #525C7A;
        border: 3px solid #E9EBF2;
      }
      &.finished {
        background: #304388;
        border: 3px solid #F8FDFF;
      }
      &.waiting {
        background: #FFCA00;
        border: 3px solid #FFF6D5;
      }
      &.happening {
        background: #FF6B00;
        border: 3px solid #FFF3E1;
      }
      &.examinating {
        background: #D94F91;
        border: 3px solid #FFE3F1;
      }
      &.dilating {
        background: #673270;
        border: 3px solid #FBF0FF;
      }
      &.pre_finished {
        background: #613F26;
        border: 3px solid #FFEFE4;
      }
      &.in_pre_consultation{
        background: #23acb1;
        border: 3px solid #FFEFE4;
      }
    }

    .option-birthdate {
      font-size: small;
      color: gray;
    }

    .option-cpf {
      font-size: small;
      color: blue;
    }

    #multiselect-container .multiselect .multiselect__tags .multiselect__spinner {
      transform: scale(0.8);
      margin-right: 7px;
    }
  }
</style>
